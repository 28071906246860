<template>
<div class="main">
    <div class="card mb-3" style="max-width: 60%;">
    <div class="row g-0">
        <div class="col-md-4">
        <img src="../assets/RMX1971.jpg" class="img-fluid rounded" alt="Realme 5 Pro">
        </div>
        <div class="col-md-8">
        <div class="card-body">
            <h5 class="card-title">Realme 5 Pro</h5>
            <p class="card-text">Codename : RMX1971<br>Processor : SDM712<br>Released on : August 2019<br>Ram : 4gb/6gb/8gb<br>Internal : 64gb/128gb</p>
        </div>
        </div>
    </div>
    </div>
      <v-chip-group
        v-model="selection"
        active-class="white--text text--accent-4"
        mandatory
      >
      <div class="chips">
        <v-chip @click="rom = true, kernel=false, recovery=false">Roms</v-chip>
        <v-chip @click="rom = false, kernel=true, recovery=false">Kernels</v-chip>
        <v-chip @click="rom = false, kernel=false, recovery=true">Recoveries</v-chip>
      </div>
      </v-chip-group>
      <div class="panels">
        <v-expansion-panels>
            <v-expansion-panel
            v-for="(item,i) in this.romslength"
            :key="i"
            v-show="rom"
            style="background:#467cc1;color:white;"
            >
            <v-expansion-panel-header>
                <div>{{ romsdata[i].name }}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div class="paneldata">
                <div class="col-md-4">
                    <img :src="romsdata[i].logo" class="img-fluid rounded" :alt="romsdata[i].name">
                </div>
                <div class="panel">
                    <p>By : <a :href="romsdata[i].maintainer" style="color:white">{{ romsdata[i].maintainer }}</a></p>
                    <p>Tag : {{ romsdata[i].tag }}</p>
                    <p>Version : {{ romsdata[i].version }}</p>
                    <p>Builddate : {{ romsdata[i].build_date }}</p>
                    <p>Device : {{ romsdata[i].device }}</p>
                    <p>Download link : <a :href="romsdata[i].download" style="color:white">{{ romsdata[i].name }}</a></p>
                    <p style="white-space: pre-line;">Changelog : <br>{{ romsdata[i].note | strippedContent }}</p>
                </div>
                </div>
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="panels">
        <v-expansion-panels>
            <v-expansion-panel
            v-for="(item,i) in this.kernelslength"
            :key="i"
            v-show="kernel"
            style="background:#467cc1;color:white;"
            >
            <v-expansion-panel-header>
                <div>{{ kernelsdata[i].name }}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div class="paneldata">
                <div class="col-md-4">
                    <img :src="kernelsdata[i].logo" class="img-fluid rounded" :alt="kernelsdata[i].name">
                </div>
                <div class="panel">
                    <p>By : <a :href="kernelsdata[i].maintainer" style="color:white">{{ kernelsdata[i].maintainer }}</a></p>
                    <p>Version : {{ kernelsdata[i].version }}</p>
                    <p>Builddate : {{ kernelsdata[i].build_date }}</p>
                    <p>Device : {{ kernelsdata[i].device }}</p>
                    <p>Download link : <a :href="kernelsdata[i].download" style="color:white">{{ kernelsdata[i].name }}</a></p>
                    <p style="white-space: pre-line;">Changelog : <br>{{ kernelsdata[i].note | strippedContent }}</p>
                </div>
                </div>
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="panels">
        <v-expansion-panels>
            <v-expansion-panel
            v-for="(item,i) in this.recoverieslength"
            :key="i"
            v-show="recovery"
            style="background:#467cc1;color:white;"
            >
            <v-expansion-panel-header>
                <div>{{ recoveriesdata[i].name }}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div class="paneldata">
                <div class="col-md-4">
                    <img :src="recoveriesdata[i].logo" class="img-fluid rounded" :alt="recoveriesdata[i].name">
                </div>
                <div class="panel">
                    <p>By : <a :href="recoveriesdata[i].maintainer" style="color:white">{{ recoveriesdata[i].maintainer }}</a></p>
                    <p>Tag : {{ recoveriesdata[i].tag }}</p>
                    <p>Version : {{ recoveriesdata[i].version }}</p>
                    <p>Builddate : {{ recoveriesdata[i].build_date }}</p>
                    <p>Device : {{ recoveriesdata[i].device }}</p>
                    <p>Download link : <a :href="recoveriesdata[i].download" style="color:white">{{ recoveriesdata[i].name }}</a></p>
                    <p style="white-space: pre-line;">Changelog : <br>{{ recoveriesdata[i].note | strippedContent }}</p>
                </div>
                </div>
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
      </div>
</div>
</template>

<script> 
import axios from 'axios';
  export default {
    name: 'Realme5Pro',
    components: {

    },
    data: () => ({
        rom: true,
        kernel: false,
        recovery: false,
        romslength: '',
        kernelslength: '',
        recoverieslength: '',
        device: 'RMX1971',
        type1: 'rom',
        type2: 'kernel',
        type3: 'recovery'
        
    }),

    mounted () {
        this.getroms();
        this.getkernels();
        this.getrecoveries();
    },

    filters: {
        strippedContent: function(string) {
            return string.replace(/<\/?[^>]+>/ig, "\n"); 
        }
    },

    methods: {
        getroms() {
            let data = {
                device: this.device,
                type: this.type1
            };
            let config = {
                method : 'post',
                url : `https://kharame-devices.herokuapp.com/getBuilds`,
                data: data
            };
            axios(config)
            .then((response) => {
                response.data.message
                this.romslength = response.data.message.length
                this.romsdata = response.data.message
            })
        },
        getkernels() {
            let data = {
                device: this.device,
                type: this.type2
            };
            let config = {
                method : 'post',
                url : `https://kharame-devices.herokuapp.com/getBuilds`,
                data: data
            };
            axios(config)
            .then((response) => {
                response.data.message
                this.kernelslength = response.data.message.length
                this.kernelsdata = response.data.message
            })
        },
        getrecoveries() {
            let data = {
                device: this.device,
                type: this.type3
            };
            let config = {
                method : 'post',
                url : `https://kharame-devices.herokuapp.com/getBuilds`,
                data: data
            };
            axios(config)
            .then((response) => {
                response.data.message
                this.recoverieslength = response.data.message.length
                this.recoveriesdata = response.data.message
            })
        },
    },
  }
</script>
<style scoped>
.card {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    background: #467cc1;
    color: whitesmoke;
}
.card-text {
    line-height: 2;
}
.chips {
    margin-left: auto;
    margin-right: auto;
}
.panels {
    margin-top: 2%;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}
.paneldata {
    display: flex;
}
.panel {
    margin-left: 2.5%;
}
@media (max-width: 550px) {
    .paneldata {
        display: block;
    }
    .panel {
        margin-top: 10%;
    }
}
</style>